
import {Component, Vue} from "vue-property-decorator";
import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";
import {
  AttachmentHostType,
  CustomFormType,
  ProjectBeneficiaryMappingDto,
  ProjectServiceItemCreateOrUpdateDto, ProjectServiceItemDto,
  ServiceRecordCreateOrUpdateDto, ServiceRecordDto,
  ServiceRecordType
} from "@/api/appService";
import api from '@/api';
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: 'CreateInternalProjectBeneficiary',
  components: {
    CustomFormEdit,
    AbSelect,
    MultipleUploadFile
  },
  filters: {
    formatCompleteStatus(status: string, completeStatusList: any[]) {
      let value: string | undefined
      if (completeStatusList && completeStatusList.length > 0) {
        completeStatusList.map((item: any) => {
          if (item.value == status) {
            value = item.text
            return
          }
        })
      }
      return value
    }
  }
})

export default class CreateInternalProjectBeneficiary extends Vue {
  dataId: number = 0;
  projectId!: number;
  formId!: number;
  type!: string;
  types = ServiceRecordType;
  isShow: boolean = false;
  canChange: boolean = false;
  beneficiary: ProjectBeneficiaryMappingDto = {}
  beneficiaryList: ProjectBeneficiaryMappingDto[] = [];
  serviceItems: ProjectServiceItemCreateOrUpdateDto[] = [];
  completeStatus = [];
  serviceMethods = [];
  serviceRecords: ProjectServiceItemDto[] = []
  detail: ServiceRecordDto = {}

  created() {
    this.fetchEnum();
    this.fetchDataDictionary();
    // 自定义表单id
    if (this.$route.query.formId) {
      this.formId = Number(this.$route.query.formId);
    }
    // 内部项目id
    if (this.$route.query.projectId) {
      this.projectId = Number(this.$route.query.projectId);
    }
    if (this.$route.query.type) {
      this.type = String(this.$route.query.type)
    }
    if (this.$route.query.id) {
      this.dataId = Number(this.$route.query.id);
      this.canChange = true
    }
    api.projectBeneficiaryMapping.getAll({projectId: this.projectId, maxResultCount: 10000}).then(res => {
      this.beneficiaryList = res.items ? res.items : [];

      if (this.dataId) {
        api.beneficiaryServiceRecord.get({id: this.dataId}).then(res => {
          this.serviceItems = res.serviceItems ?? [];
          if (this.serviceItems && this.serviceItems!.length > 0) {
            this.serviceItems.map(item => {
              item.savedAttachments = item.savedAttachments ?? []
            })
          }

          this.beneficiary.id = res.beneficiaryId;
          this.getServiceRecords(Number(res.beneficiaryId));

          if (this.beneficiaryList) {
            this.beneficiaryList.map(item => {
              if (item.beneficiaryId === this.beneficiary.id) {
                this.beneficiary.principalName = item.principalName;
                this.beneficiary.principalPhone = item.principalPhone;

                this.beneficiary.id = item.id;
                this.isShow = true;
                return;
              }
            })
          }
        })
      }
    })
  }

  get hostType() {
    return CustomFormType.BeneficiaryServiceRecord;
  }



  chooseBeneficiary() {

    api.projectBeneficiaryMapping.get({id: this.beneficiary.id}).then(res => {
      this.beneficiary = res;
      this.getServiceRecords(Number(this.beneficiary.beneficiaryId));
      this.isShow = true;
    })
  }

  getServiceRecords(beneficiaryId: number) {
    api.beneficiaryServiceRecord.getBeneficiaryRecordList({
      beneficiaryId: beneficiaryId
    }).then((res) => {
      Object.assign(this.serviceRecords, res);
      if (this.serviceRecords) {
        this.serviceRecords.map(item => {
          item.serviceMethod = item.serviceMethods?.join(',')
        })
      }
      console.log("serviceRecords=" + JSON.stringify(this.serviceRecords))
      this.$forceUpdate()
    })
  }

  get getData() {
    if (this.dataId) {
      return api.beneficiaryServiceRecord.get({id: Number(this.dataId)});
    } else {
      return
    }
  }

  get hostTypeFile() {
    return AttachmentHostType.Internal_ServiceRecord;
  }

  fetchEnum() {
    api.enumService.getValues({typeName: 'ProjectServiceRecordCompleteStatus'}).then((res: any) => {
      this.completeStatus = res
    });
  }

  fetchDataDictionary() {
    api.dataDictionary.getDataDictionaryListByKey({key: 'ServiceMethod', maxResultCount: 1000})
      .then((res: any) => {
        this.serviceMethods = res.items
      })
  }

  handleSave() {
    (this.$refs.customForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.beneficiary.beneficiaryId) {
          this.$message.error('请先选择受益人');
          return;
        }
        const data = (this.$refs.customForm as any).getRequestData() as ServiceRecordCreateOrUpdateDto;
        data.projectId = this.projectId;
        data.beneficiaryId = this.beneficiary.beneficiaryId;
        data.projectBeneficiaryMappingId = this.beneficiary.id;
        data.serviceRecordType = ServiceRecordType.Beneficiary;
        data.serviceItems = this.serviceItems;
        data.formId = this.formId; // formId必传
        if (data.serviceItems) {
          data.serviceItems.map(item => {
            item.serviceMethod = Array(item.serviceMethods).join(',');
          })
        }

        if (this.dataId) {
          api.beneficiaryServiceRecord.update({body: data}).then(() => {
            this.$message.success('更新成功');
            this.$router.back();
          });
        } else {
          data.id = 0;
          api.beneficiaryServiceRecord.create({body: data}).then(() => {
            this.$message.success('新建成功');
            this.$router.back();
          });
        }
      }
    })
  }

  cancel() {
    this.$router.back();
  }

  //增加服务记录
  private addRow(r: number) {
    const obj = {
      id: undefined,
      serviceTime: undefined,
      serviceMethods: undefined,
      serviceContent: undefined,
      target: undefined,
      completeStatus: undefined,
      remark: undefined,
      savedAttachments: []
    };

    this.serviceItems = [...this.serviceItems!, obj];
  }

  // 删除服务记录
  private deleteRow(index: number) {
    this.serviceItems!.splice(index, 1);
  }

  handleDetail(row: any) {
    const hostType = this.type === ServiceRecordType.Beneficiary ? CustomFormType.BeneficiaryServiceRecord : CustomFormType.SchoolServiceRecord;
    console.log("hostType=" + this.hostType)
    console.log("projectId=" + JSON.stringify(row))
    api.customFormService.getCustomFormId({hostType: hostType, hostId: String(row.projectId)}).then((res) => {
      console.log("res=" + JSON.stringify(res))
      this.$router.push({
        name: "internalProjectSchoolServiceRecordDetail",
        query: {
          id: row.id,
          formId: this.formId.toString(),
          type: 'Beneficiary'
        }
      });
    })
  }
}
