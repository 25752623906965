
  import {Component, Ref, Vue, Watch} from "vue-property-decorator";
  import api from "@/api/index";
  import PagedTableView from "@/components/PagedTableView/index.vue";
  import AbSelect from "@/components/AbSelect/index.vue";
  import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
  import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";
  import {BeneficiaryCreateOrUpdateDto, CustomFormType, InternalProjectDto, ServiceRecordType} from "@/api/appService";

  @Component({
    name: "internalProjectBeneficiaryServiceRecordIndex",
    components: {
      PagedTableView,
      AbSelect,
      AgileAuditTag,
      AuditFlowDetailButton
    }
  })

  export default class InternalProjectBeneficiaryServiceRecordIndex extends Vue {
    @Ref() readonly pagedTableView!: any;
    type:ServiceRecordType = ServiceRecordType.Beneficiary;
    beneficiary:BeneficiaryCreateOrUpdateDto = {}
    serviceRecordType:string = ''
    queryForm = {
      displayName: undefined,
    }

    created() {
      if (this.$route.params.type === 'school') {
        this.serviceRecordType = '组织';
        this.type = ServiceRecordType.School;
      } else {
        this.serviceRecordType = '受益人';
      }
    }

    @Watch('$route.params')
    changeRoute(newValue:any){
      if (this.$route.params.type === 'school') {
        this.serviceRecordType = '组织';
        this.type = ServiceRecordType.School;
      } else {
        this.serviceRecordType = '受益人';
        this.type = ServiceRecordType.Beneficiary;
      }
      this.pagedTableView!.fetchData();
    }

    fetchData(params: any) {
      return api.internalProject.getAll(params);
    }

    handleServiceList(row: InternalProjectDto) {
      const hostType = this.type === ServiceRecordType.Beneficiary ? CustomFormType.BeneficiaryServiceRecord : CustomFormType.SchoolServiceRecord;
      api.customFormService.getCustomFormId({hostType: hostType, hostId: String(row.projectId)}).then(res => {
        if (!res) {
          this.$message.warning('暂无内部项目'+ this.serviceRecordType +'服务记录自定义表单!');
          // this.$confirm('暂无内部项目'+ this.serviceRecordType +'服务记录自定义表单，是否前往创建?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(() => {
          //   this.$router.push({
          //     name: 'projectCustomForm',
          //     query: {
          //       id: String(row.projectId,)
          //     }
          //   })
          // });
        } else {
          this.$router.push({
            name: 'internalProjectBeneficiaryServiceRecordList',
            query: {
              projectId: String(row.projectId),
              type: this.type,
              formId: res,
              id: String(row.id)
            }
          })
        }
      })
    }
  }
